var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pa-4"},[_c('navbar-profile',{attrs:{"items":_vm.items}})],1),_c('div',{staticClass:"px-5"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('div',{staticClass:"mt-2 txt_search"},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"hide-details":"","solo":"","flat":"","background-color":"#CFE1E1","placeholder":"Search for people","prepend-inner-icon":"mdi-magnify"},on:{"keyup":_vm.fetchData},model:{value:(_vm.find),callback:function ($$v) {_vm.find=$$v},expression:"find"}})],1)]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"mt-2 d-flex align-center"},[_c('h4',{staticClass:"mr-2"},[_vm._v("Show")]),_c('v-menu',{attrs:{"elevation":"0","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"min-width":"60px","max-width":"60px","outlined":"","depressed":""}},'v-btn',attrs,false),on),[_c('p',{staticClass:"ma-0 default_txt"},[_vm._v(_vm._s(_vm.limit))]),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}]),model:{value:(_vm.menuLimit),callback:function ($$v) {_vm.menuLimit=$$v},expression:"menuLimit"}},[_c('v-card',{staticClass:"popup_radius",attrs:{"outlined":"","elevation":"0"}},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.changeLimit(10)}}},[_c('v-list-item-title',[_vm._v("10")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.changeLimit(50)}}},[_c('v-list-item-title',[_vm._v("50")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.changeLimit(100)}}},[_c('v-list-item-title',[_vm._v("100")])],1)],1)],1)],1)],1)])],1),_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"py-2",attrs:{"cols":"12","md":"4"}},[_c('bullet',{attrs:{"title":"List Manajemen Tugas"}})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[(!_vm.loading)?_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"end"}},[_c('div',{staticClass:"col_tollbar"},[_c('v-btn',{staticClass:"text-capitalize btn_tollbar rounded-lg bg_color1",attrs:{"depressed":"","dark":""},on:{"click":_vm.createData}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-plus")]),_vm._v(" Tambah ")],1)],1),_c('div',{staticClass:"col_tollbar"},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize btn_tollbar rounded-lg",attrs:{"dark":"","color":"#CFE1E1"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#106967"}},[_vm._v(" "+_vm._s(_vm.icon_sort))]),_c('h4',{staticClass:"ml-2",staticStyle:{"color":"#106967"}},[_vm._v(" "+_vm._s(_vm.sort ? _vm.sort == "task_management.status" ? "Status" : "Tugas" : "Sort")+" ")])],1)]}}],null,false,1786147972),model:{value:(_vm.sort_menu),callback:function ($$v) {_vm.sort_menu=$$v},expression:"sort_menu"}},[_c('v-list',[_c('v-list-item',[_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"block":"","text":""},on:{"click":function($event){return _vm.changedesc(
                          _vm.icon_sort == 'mdi-sort-ascending'
                            ? 'mdi-sort-descending'
                            : 'mdi-sort-ascending'
                        )}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.icon_sort))]),_vm._v(" Sort ")],1)],1),_c('v-btn',{attrs:{"icon":"","fab":"","x-small":""},on:{"click":function($event){_vm.sort_menu = false}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1),_c('v-list-item',[_c('v-btn',{class:_vm.sort == 'task_management.status'
                        ? 'text-capitalize grey'
                        : 'text-capitalize white',attrs:{"block":"","dark":_vm.sort == 'task_management.status' ? true : false,"text":""},on:{"click":function($event){return _vm.changeSort('task_management.status')}}},[_vm._v("Status ")])],1),_c('v-list-item',[_c('v-btn',{class:_vm.sort == 'task_management.step_id'
                        ? 'text-capitalize grey'
                        : 'text-capitalize white',attrs:{"block":"","dark":_vm.sort == 'task_management.step_id' ? true : false,"text":""},on:{"click":function($event){return _vm.changeSort('task_management.step_id')}}},[_vm._v(" Tugas ")])],1)],1)],1)],1)]):_vm._e()],1)],1)],1),_c('v-card',{staticClass:"card_on_view",attrs:{"flat":""}},[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"pa-2 elevation-0",attrs:{"type":"table-tbody"}}):_vm._e(),(!_vm.loading && _vm.dataSet)?_c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.dataSet,"hide-default-footer":"","page":_vm.page,"items-per-page":_vm.limit,"single-select":false},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.step",fn:function(ref){
                        var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-start pa-1 txt_white text-capitalize bg_color1",staticStyle:{"width":"100%","height":"100%"}},[_vm._v(" "+_vm._s(item.step ? item.step.step : "Data tidak ditemukan")+" ")])]}},{key:"item.karyawan.avatar",fn:function(ref){
                        var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center pa-1 txt_white text-capitalize bg_color1",staticStyle:{"width":"100%","height":"100%"}},[_c('v-avatar',{attrs:{"size":"50"}},[_c('img',{staticClass:"obj_fit",staticStyle:{"background":"white","padding":"1px"},attrs:{"src":item.karyawan
                    ? item.karyawan.avatar
                      ? item.karyawan.avatar
                      : _vm.ava
                    : _vm.ava}})])],1)]}},{key:"item.status",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center pa-1 txt_white text-capitalize",style:(("width: 100%; height: 100%; background: " + (item.status == 'done'
                ? '#26AF65'
                : item.status == 'ongoing'
                ? '#EDD57B'
                : '#DC3545')))},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.prioritas",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center pa-1 txt_white text-capitalize",style:(("width: 100%; height: 100%; background: " + (item.prioritas == 'low'
                ? '#408785'
                : item.prioritas == 'high'
                ? '#633B18'
                : '#BD9028')))},[_vm._v(" "+_vm._s(item.prioritas)+" ")])]}},{key:"item.project",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center pa-1 txt_white text-capitalize bg_color1",staticStyle:{"width":"100%","height":"100%"}},[_vm._v(" "+_vm._s(((item.event.layanan) + " " + (item.event.tipe == "individu" ? "(I)" : "(P)") + " - " + (item.klien.nama)))+" ")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center pa-1 bg_color1",staticStyle:{"width":"100%","height":"100%"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","depressed":"","dark":"","small":""},on:{"click":function($event){return _vm.updateData(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Tugas")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","depressed":"","dark":"","small":"","to":("/admin/mk/task-management/detail-task/" + (item.id))}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-account-details ")])],1)]}}],null,true)},[_c('span',[_vm._v("Lihat Rincian Tugas")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","depressed":"","to":("/admin/mk/task-management/detail/" + (item.id)),"dark":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Lihat Detail")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","depressed":"","small":""},on:{"click":function($event){return _vm.deleteData(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","small":"","color":"red"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Tugas")])])],1)]}}],null,true)}),_c('div',{staticClass:"text-center pa-3"},[_c('v-pagination',{attrs:{"color":"#052633","length":_vm.last_page},on:{"input":_vm.fetchData},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1):_vm._e()],1),_c('div',{staticClass:"pa-2"},[_c('dialog-medium',{attrs:{"d_medium":_vm.d_create,"type":_vm.d_type,"title":_vm.d_title,"inventory":_vm.inventory,"line":_vm.line},on:{"close":function($event){_vm.d_create = false},"refetch":_vm.fetchData}}),_c('dialog-small',{attrs:{"d_small":_vm.d_small,"inventory":_vm.inventory,"type":_vm.d_type,"title":_vm.d_title},on:{"refetch":_vm.fetchData,"close":function($event){_vm.d_small = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }