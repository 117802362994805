<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <v-row no-gutters>
        <v-col cols="12" md="10">
          <div class="mt-2 txt_search">
            <v-text-field
              v-model="find"
              hide-details
              solo
              flat
              background-color="#CFE1E1"
              class="rounded-lg"
              placeholder="Search for people"
              prepend-inner-icon="mdi-magnify"
              @keyup="fetchData"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="2" class="d-flex justify-end">
          <div class="mt-2 d-flex align-center">
            <h4 class="mr-2">Show</h4>
            <v-menu
              v-model="menuLimit"
              elevation="0"
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  min-width="60px"
                  max-width="60px"
                  outlined
                  depressed
                  class="text-capitalize"
                  v-bind="attrs"
                  v-on="on"
                >
                  <p class="ma-0 default_txt">{{ limit }}</p>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card class="popup_radius" outlined elevation="0">
                <v-list>
                  <v-list-item link @click="changeLimit(10)">
                    <v-list-item-title>10</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(50)">
                    <v-list-item-title>50</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(100)">
                    <v-list-item-title>100</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4" align="center">
        <v-col cols="12" md="4" class="py-2">
          <bullet title="List Manajemen Tugas" />
        </v-col>
        <v-col cols="12" md="8">
          <v-row no-gutters align="center" justify="end" v-if="!loading">
            <div class="col_tollbar">
              <v-btn
                depressed
                class="text-capitalize btn_tollbar rounded-lg bg_color1"
                dark
                @click="createData"
              >
                <v-icon class="mr-2"> mdi-plus</v-icon> Tambah
              </v-btn>
            </div>
            <div class="col_tollbar">
              <v-menu
                :close-on-content-click="false"
                v-model="sort_menu"
                transition="slide-y-transition"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    color="#CFE1E1"
                    class="text-capitalize btn_tollbar rounded-lg"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="#106967"> {{ icon_sort }}</v-icon>
                    <h4 class="ml-2" style="color: #106967">
                      {{
                        sort
                          ? sort == "task_management.status"
                            ? "Status"
                            : "Tugas"
                          : "Sort"
                      }}
                    </h4>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <div class="d-flex align-center">
                      <v-btn
                        @click="
                          changedesc(
                            icon_sort == 'mdi-sort-ascending'
                              ? 'mdi-sort-descending'
                              : 'mdi-sort-ascending'
                          )
                        "
                        block
                        class="text-capitalize"
                        text
                      >
                        <v-icon class="mr-2">{{ icon_sort }}</v-icon>
                        Sort
                      </v-btn>
                    </div>
                    <v-btn @click="sort_menu = false" icon fab x-small>
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                      block
                      :class="
                        sort == 'task_management.status'
                          ? 'text-capitalize grey'
                          : 'text-capitalize white'
                      "
                      :dark="sort == 'task_management.status' ? true : false"
                      text
                      @click="changeSort('task_management.status')"
                      >Status
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                      block
                      :class="
                        sort == 'task_management.step_id'
                          ? 'text-capitalize grey'
                          : 'text-capitalize white'
                      "
                      :dark="sort == 'task_management.step_id' ? true : false"
                      text
                      @click="changeSort('task_management.step_id')"
                    >
                      Tugas
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-card class="card_on_view" flat>
      <v-skeleton-loader
        class="pa-2 elevation-0"
        type="table-tbody"
        v-if="loading"
      ></v-skeleton-loader>
      <div v-if="!loading && dataSet">
        <v-data-table
          :headers="headers"
          :items="dataSet"
          hide-default-footer
          :page.sync="page"
          :items-per-page="limit"
          :single-select="false"
          class="elevation-0"
        >
          <template v-slot:[`item.step`]="{ item }">
            <div
              class="d-flex align-center justify-start pa-1 txt_white text-capitalize bg_color1"
              style="width: 100%; height: 100%"
            >
              {{ item.step ? item.step.step : "Data tidak ditemukan" }}
            </div>
          </template>
          <template v-slot:[`item.karyawan.avatar`]="{ item }">
            <div
              class="d-flex align-center justify-center pa-1 txt_white text-capitalize bg_color1"
              style="width: 100%; height: 100%"
            >
              <v-avatar size="50">
                <img
                  :src="
                    item.karyawan
                      ? item.karyawan.avatar
                        ? item.karyawan.avatar
                        : ava
                      : ava
                  "
                  class="obj_fit"
                  style="background: white; padding: 1px"
                />
              </v-avatar>
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div
              class="d-flex align-center justify-center pa-1 txt_white text-capitalize"
              :style="`width: 100%; height: 100%; background: ${
                item.status == 'done'
                  ? '#26AF65'
                  : item.status == 'ongoing'
                  ? '#EDD57B'
                  : '#DC3545'
              }`"
            >
              {{ item.status }}
            </div>
          </template>
          <template v-slot:[`item.prioritas`]="{ item }">
            <div
              class="d-flex align-center justify-center pa-1 txt_white text-capitalize"
              :style="`width: 100%; height: 100%; background: ${
                item.prioritas == 'low'
                  ? '#408785'
                  : item.prioritas == 'high'
                  ? '#633B18'
                  : '#BD9028'
              }`"
            >
              {{ item.prioritas }}
            </div>
          </template>
          <template v-slot:[`item.project`]="{ item }">
            <div
              class="d-flex align-center justify-center pa-1 txt_white text-capitalize bg_color1"
              style="width: 100%; height: 100%"
            >
              {{
                `${item.event.layanan} ${
                  item.event.tipe == "individu" ? "(I)" : "(P)"
                } -  ${item.klien.nama}`
              }}
            </div>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div
              class="d-flex align-center justify-center pa-1 bg_color1"
              style="width: 100%; height: 100%"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    depressed
                    class="mr-2"
                    @click="updateData(item)"
                    dark
                    small
                  >
                    <v-icon dark small> mdi-pencil </v-icon>
                  </v-btn>
                </template>
                <span>Edit Tugas</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    depressed
                    class="mr-2"
                    dark
                    small
                    :to="`/admin/mk/task-management/detail-task/${item.id}`"
                  >
                    <v-icon dark small> mdi-account-details </v-icon>
                  </v-btn>
                </template>
                <span>Lihat Rincian Tugas</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    depressed
                    class="mr-2"
                    :to="`/admin/mk/task-management/detail/${item.id}`"
                    dark
                    small
                  >
                    <v-icon dark small> mdi-eye </v-icon>
                  </v-btn>
                </template>
                <span>Lihat Detail</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    depressed
                    @click="deleteData(item)"
                    small
                  >
                    <v-icon dark small color="red"> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span>Delete Tugas</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
        <div class="text-center pa-3">
          <v-pagination
            color="#052633"
            v-model="page"
            @input="fetchData"
            :length="last_page"
          ></v-pagination>
        </div>
      </div>
    </v-card>
    <div class="pa-2">
      <dialog-medium
        :d_medium="d_create"
        :type="d_type"
        @close="d_create = false"
        :title="d_title"
        :inventory="inventory"
        :line="line"
        @refetch="fetchData"
      />
      <dialog-small
        :d_small="d_small"
        @refetch="fetchData"
        @close="d_small = false"
        :inventory="inventory"
        :type="d_type"
        :title="d_title"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  name: "listTaskManagement",
  components: { bullet, NavbarProfile },
  computed: {
    ...mapState({
      drawer: (state) => state.drawer,
      ava: (state) => state.ava,
      profile: (state) => state.profile,
    }),
  },
  data() {
    return {
      menuLimit: false,
      sort_menu: false,
      icon_sort: "mdi-sort-ascending",
      loading: false,
      dataSet: null,
      find: "",
      page: 1,
      limit: 10,
      last_page: null,
      total_page: null,
      sort: "",
      d_create: false,
      d_small: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      dataEvent: null,
      dataKaryawan: null,
      headers: [
        {
          text: "Managemen Tugas",
          value: "step",
          align: "center",
          width: "35%",
        },
        {
          text: "Karyawan",
          value: "karyawan.avatar",
          align: "center",
          width: "90px",
        },
        { text: "Status", value: "status", width: "15%", align: "center" },
        {
          text: "Prioritas",
          value: "prioritas",
          width: "15%",
          align: "center",
        },
        {
          text: "Project",
          align: "center",
          value: "project",
          width: "20%",
        },
        { text: "", value: "actions", sortable: false },
      ],
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "List Manajemen Tugas",
          disabled: true,
          href: "#",
        },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    changeSort(item) {
      this.sort = item;
      this.sort_menu = false;
      this.fetchData();
    },
    changedesc(item) {
      this.icon_sort = item;
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/task-management?page[size]=${this.limit}&page[number]=${
          this.page
        }&sort=${
          this.sort ? (this.icon_sort == "mdi-sort-ascending" ? "-" : "+") : ""
        }${this.sort}&filter[nama]=${this.find}`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.dataSet = data.data;
          this.last_page = data.last_page;
          this.total_page = data.total;
          this.fetchDataEvent();
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    changeLimit(val) {
      this.limit = val;
      this.fetchData();
    },
    deleteData(item) {
      this.inventory = {
        data: item,
        path: `sysadmin/task-management/delete`,
        deleteType: "Manajemen Tugas",
      };
      this.d_type = "delete";
      this.d_title = "Hapus Tugas";
      this.d_small = true;
      this.line = true;
    },
    createData() {
      this.inventory = {
        event: this.dataEvent,
      };
      this.d_type = "c_task";
      this.d_title = "Tambah Tugas";
      this.line = true;
      setTimeout(() => {
        this.d_create = true;
      }, 300);
    },
    async updateData(item) {
      let idx = null;
      for (let i = 0; i < this.dataEvent.length; i++) {
        if (item.event_id == this.dataEvent[i].id) {
          idx = i;
          break;
        }
      }
      this.inventory = {
        event: this.dataEvent,
        karyawan: this.dataKaryawan,
        data: item,
        idx: idx,
      };
      this.d_type = "u_task";
      this.d_title = "Edit Tugas";
      this.d_create = true;
      this.line = true;
    },
    fetchDataEvent() {
      let data = {
        path: `sysadmin/event`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.dataEvent = data.data;
          this.fetchDataKaryawan();
        })
        .catch((e) => {
          this.loading = false;
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
        });
    },
    fetchDataKaryawan() {
      let data = {
        path: `sysadmin/karyawan/akun/list?filter[klien_id]=${this.profile.data.account.klien.id}`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.dataKaryawan = data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
        });
    },
  },
};
</script>

<style scoped>
>>> .v-data-table tbody tr:nth-child(even) {
  background-color: white !important;
}
.v-pagination__item {
  box-shadow: none !important;
}
.v-pagination__navigation {
  box-shadow: none !important;
}
.txt_large {
  font-size: 18px;
}
</style>
